import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "#fff",
      },
      root: {
        boxSizing: " border-box",
        color: "#000",
        backgroundColor: "#fff",
        borderRadius: "5px",
        fontSize: "16px",
        transition: "0.5s",
        height: "46px",
        fontWeight: "700",
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
        },
      },
    },
    MuiPaper: {
      elevation8: {
        backgroundColor: "#fff",
      },
    },
    MuiAppBar: {
      positionFixed: {
        backgroundColor: "#272a30",
      },
      positionAbsolute: {
        top: "10",
        left: "auto",
        right: "0",
        position: "absolute",
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: "#4682b4",
        "&:hover": {
          backgroundColor: "#000",
        },
      },
      containedSecondary: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        color: "#fff",
        minWidth: "180px",
        fontSize: "16px",
        transition: "0.5s",
        height: "46px",
        marginRight: "10px",
        borderRadius: "5px",
        fontWeight: "700",
        backgroundColor: "#4682b4",
        "@media (max-width:767px)": {
          fontSize: "12px !important",
          minWidth: "120px",
        },
        "&:hover": {
          backgroundColor: "#000",
        },
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Roboto', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
