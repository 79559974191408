import React from "react";
import {
  Box,
  Container,
  makeStyles,
  Link,
} from "@material-ui/core";
import { FaDiscord } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    backgroundColor: "#fff",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
    },
    "& p": {
      fontSize:"12px",
      margin:"0",
      padding:"10px 0"
    },
  },
  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    margin:"0",
    justifyContent: "center",
    "& a": {
      backgroundColor: " #fff",
      color: "#000",
      margin: "0 5px",
      fontSize: "25px",
      cursor: "pointer",
      padding:"0",
      "&:hover":{
        color:"#4682b4",
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (

    <Box className={classes.footerSection} >
      <Container maxWidth="lg" align="center">
        <img src="images/footer-logo.webp" alt="Logo"  />
        <ul className={classes.menuul}>
        <li> <div> <Link   href="https://www.instagram.com/accounts/login/"  target="_blank"   rel="noopener noreferrer"><AiOutlineInstagram/></Link></div></li>
        <li><div><Link href="https://twitter.com/TigonTamer"  target="_blank"   rel="noopener noreferrer"><AiOutlineTwitter/></Link></div></li>
        <li><div><Link href="https://discord.com/invite/dPXrZEk72f"  target="_blank"   rel="noopener noreferrer"><FaDiscord/></Link></div></li>
        </ul>
        <p>© 2021 ORDER OF THE TIGONS. ALL RIGHTS RESERVED.</p>
      </Container>
    </Box>

  );
}